@value desktop-s, tablet, main-dark, secondary-font from "@/styles/variables.module.css";

.container {
  position: relative;
}

.image {
  cursor: pointer;
  border-radius: 2px;
  width: auto;
  min-width: 350px;
  max-width: 700px;
  height: 480px;
  object-fit: cover;
}

.sliderPrevArrow,
.sliderNextArrow {
  display: none;

  @media tablet {
    display: flex;
  }

  position: absolute;
  /* minus description block height */
  top: calc(50% - 26px);
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  border: none;
  border-radius: 2px;
  background-color: main-dark;
  padding: 12px;
}

.sliderPrevArrow {
  left: 0;
}

.sliderNextArrow {
  right: 0;
}

.mainDescription {
  margin: 10px 0;
  color: main-dark;
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  font-family: secondary-font;
  letter-spacing: -1%;
  text-align: center;
}
