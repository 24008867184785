@value main-dark, secondary-font, desktop-l, desktop-s from '@/styles/variables.module.css';

.topWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topWrapper.fullWidth {
  @media desktop-l {
    padding-left: 48px;
  }
}

.navigationToLeft {
  justify-content: start;
}

.sliderControls {
  display: flex;
  column-gap: 12px;
}

.sliderArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0;
  border: none;
  background-color: transparent;
  padding: 10px 12px;

  @media desktop-s {
    padding: 26px 28px;
  }
}

.sliderArrow:hover {
  border-radius: 2px;
  background-color: main-dark;
}

.sliderArrow:hover svg path {
  fill: #fff;
}
