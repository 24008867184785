@value desktop-s from "@/styles/variables.module.css";

.container {
  display: grid;
  gap: 16px;

  @media desktop-s {
    grid-template-columns: repeat(2, 1fr);
    gap: 28px;
    padding-top: 36px;
  }
}
