@value desktop-s from '@/styles/variables.module.css';

.cardsContainer {
  display: flex;
  row-gap: 16px;
  flex-direction: column;

  @media desktop-s {
    column-gap: 28px;
    flex-direction: row;
  }
}

.cardsContainer > * {
  flex: 1;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 28px;

  @media desktop-s {
    gap: 48px;
    margin-top: 64px;
  }
}
