.bookmarkIcon {
  cursor: pointer;
}

.bookmarkButton {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin: 0;
  border: none;
  background-color: transparent;
  padding: 0;
  width: 100%;
  height: 100%;
}
