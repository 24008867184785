@value secondary-font, tablet, desktop-s from "@/styles/variables.module.css";

.sectionContainer {
  display: flex;
  flex-direction: column;

  @media tablet {
    justify-content: center;
    align-items: center;
  }
}

.image {
  width: 100%;
  max-width: 880px;
  height: auto;
}

.credits {
  align-self: center;
  margin: 0;
  color: #000;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  font-family: secondary-font;
}
