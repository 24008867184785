@value main-dark, main-font, secondary-font,tablet,desktop-s from '@/styles/variables.module.css';

.title {
  margin: 0;
  color: #fff;
  font-weight: 400;
  font-size: 28px;
  line-height: 44px;
  font-family: main-font;
  text-align: left;
  text-decoration-skip-ink: none;
  text-underline-position: from-font;

  @media desktop-s {
    font-size: 36px;
    line-height: 48px;
    letter-spacing: 0.01em;
  }
}

.container {
  display: flex;
  row-gap: 24px;
  flex-direction: column;
  margin-bottom: 20px;
  background-color: main-dark;
  width: 100%;
}
