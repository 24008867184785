@value main-font, secondary-font, desktop-m, desktop-s from "@/styles/variables.module.css";

.header {
  margin-bottom: 24px;

  @media desktop-s {
    margin-bottom: 72px;
  }
}

.title {
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  font-family: main-font;
  letter-spacing: -0.02em;
  text-align: center;

  @media desktop-s {
    font-size: 96px;
    line-height: 96px;
  }
}

.description {
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  font-family: secondary-font;
  text-align: center;
}

.videoContainer {
  display: flex;
  position: relative;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.videoHeader {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(13, 13, 13, 0.4);
  width: 100%;
  height: 100%;
}

.video {
  aspect-ratio: 290 / 331;
  width: 100%;
  pointer-events: none;
}

.videoTitle {
  margin: 0;
  margin-bottom: 16px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  font-family: main-font;
  letter-spacing: -0.02em;
  text-align: center;

  @media desktop-s {
    font-size: 48px;
    line-height: 64px;
  }
}

.videoButton.videoButton {
  padding: 8px 24px;
  width: auto;
  height: auto;
  text-transform: uppercase;

  @media desktop-s {
    padding: 14px 24px;
  }
}
