@value secondary-font, main-font, tablet, desktop-s, main-dark, light-peach from "@/styles/variables.module.css";

.container {
  margin: auto;
  padding: 48px 24px;

  @media tablet {
    width: 663px;
  }

  @media desktop-s {
    width: 656px;
  }
}

.title {
  margin: 0;
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  font-family: main-font;
  text-align: center;
}

.content {
  margin: 0;
  margin-top: 20px;
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
  font-family: secondary-font;
  text-align: center;
}

.button {
  margin-top: 44px;
  text-transform: uppercase;
}

.titleDark {
  color: #fff;
}

.contentDark {
  color: #fff;
}

.wrapper {
  margin: 24px 0;
  background-color: light-peach;

  @media desktop-s {
    margin: 48px 0;
  }
}

.wrapperDark {
  background-color: main-dark;
}
