@value ornament-font, secondary-font, desktop-s, tablet, mobile-l from '@/styles/variables.module.css';

.topSection {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-top: 24px;
  padding-bottom: 48px;
}

.gallery {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  padding-bottom: 36px;
  width: 100%;

  @media desktop-s {
    grid-template-rows: repeat(2, auto);
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 28px;
  }
}

.large {
  grid-row: span 2;
  grid-column: span 2;
}

.item {
  display: block;
  position: relative;
  flex: 1;
  aspect-ratio: 1 / 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
}

.item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ornamentFont {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  font-family: ornament-font;
  letter-spacing: -1%;
  text-wrap: nowrap;
}

.instagramButton {
  height: 33px;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  font-family: secondary-font;
}

.instagramMediaTypeIcon {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 12px;
  height: 12px;
}
