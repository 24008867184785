@value ornament-font, light-peach, secondary-font from "@/styles/variables.module.css";

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  background-color: light-peach;
  padding: 48px 16px;
}

.section * {
  margin: 0;
  padding: 0;
}

.dark {
  background-color: black;
  color: white;
}

.dark svg path {
  fill: white;
}

.title {
  font-weight: 400;
  font-size: 28px;
  line-height: 44px;
  font-family: Ogg;
  text-align: center;
}

.dark .title {
  color: white;
}

.blockquote {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  max-width: 882px;
}

.blockquoteTextBlock {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  font-family: secondary-font;
  text-align: center;
}

.dark .blockquoteTextBlock,
.dark .blockquoteTextBlock * {
  color: white;
}

.footer {
  font-weight: 400;
  font-size: 24px;
  line-height: 23px;
  font-family: ornament-font;
}
