@value main-dark, secondary-font, main-font, desktop-s from '@/styles/variables.module.css';

.container {
  padding: 24px 0;

  @media desktop-s {
    padding: 64px 0 48px 0;
  }
}

.headerContainer {
  display: flex;
  flex: 1;
  align-items: center;
}

.topWrapper {
  margin-bottom: 28px;

  @media desktop-s {
    margin-bottom: 64px;
  }
}

.sliderControls {
  display: none;

  @media desktop-s {
    display: flex;
  }
}

/* TODO: fix classname priority in sliders */
.sliderWrapper.sliderWrapper.sliderWrapper {
  overflow: visible;
}

.headerOrnament {
  margin-left: 24px;
  border: 0.5px solid main-dark;
  width: 100%;

  @media desktop-s {
    margin-right: 12px;
    margin-left: 24px;
  }
}

.headerTitle {
  margin: 0;
  color: main-dark;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 64px;
  font-family: main-font;

  @media desktop-s {
    font-size: 96px;
    line-height: 96px;
  }
}

.buttonContainer {
  margin: 0 auto;
  margin-top: 28px;
  width: 160px;

  @media desktop-s {
    margin-top: 64px;
  }
}

.buttonMore {
  padding: 8px 24px;
}
