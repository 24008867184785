@value tablet from '@/styles/variables.module.css';

.divider {
  background-color: #000;
  width: 100%;
  height: 1px;
  overflow: visible;

  @media tablet {
    width: 100vw;
  }
}
