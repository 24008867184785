@values main-font, main-dark, secondary-font, desktop-s from '@/styles/variables.module.css';

.container {
  display: flex;
  row-gap: 24px;
  flex-direction: column;

  @media desktop-s {
    row-gap: 48px;
    margin-top: 24px;
  }
}

@value titleHeight: 48px;
@value titleHeightDesktop: 96px;

.title {
  margin: 0;
  color: main-dark;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 64px;
  font-family: main-font;
  text-align: center;

  @media desktop-s {
    font-size: 96px;
    line-height: 96px;
  }
}

.description {
  margin: 0;
  color: main-dark;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  font-family: secondary-font;
  text-align: center;
}

.slide {
  position: relative;
  width: 200px;
  height: 388px;

  @media desktop-s {
    width: 400px;
    height: 776px;
  }
}

.tagImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.tagCtaContainer {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: all 0.3s ease;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
}

.tagCtaContainer:hover {
  background-color: transparent;
}

.tagCtaContainer:hover .tagButton {
  @media desktop-s {
    opacity: 1;
  }
}

.tagButton.tagButton {
  opacity: 0;
  transition: all 0.3s ease;
  margin-top: 24px;
  border: none;
  padding: 8px 24px;
  width: auto;
  height: auto;

  @media desktop-s {
    padding: 14px 24px;
  }
}

.tagName {
  margin: 0;
  margin-top: 48px;
  padding: 0 12px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 44px;
  font-family: main-font;
  text-align: center;

  @media desktop-s {
    font-size: 48px;
    line-height: 64px;
  }
}
