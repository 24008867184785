@value main-dark, secondary-font, main-font, tablet, desktop-s, desktop-m, desktop-l, desktop-xl from '@/styles/variables.module.css';

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 0;

  @media tablet {
    flex-direction: row;
    gap: 48px;
  }

  @media desktop-s {
    padding: 64px 0 48px 0;
  }
}

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media tablet {
    max-width: 325px;
  }
}

.topWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
}

/* TODO: fix classname priority in sliders */
.sliderWrapper.sliderWrapper.sliderWrapper {
  overflow: visible;

  @media tablet {
    clip-path: inset(0 -300px 0 0);
    max-width: 400px;
  }

  @media desktop-s {
    max-width: 620px;
  }

  @media desktop-m {
    clip-path: inset(0 -1000px 0 0);
    max-width: 700px;
  }

  @media desktop-l {
    max-width: 800px;
  }
}

.headerTitle {
  margin: 0;
  width: 90%;
  color: main-dark;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  font-family: main-font;
  text-align: start;

  @media desktop-s {
    font-size: 48px;
    line-height: 64px;
  }
}

.buttonContainer {
  display: none;

  @media tablet {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.button {
  text-transform: uppercase;
}

.sliderArrow {
  width: 68px;
  height: 72px;
}

.sliderControls {
  display: none;
  align-self: flex-start;

  @media tablet {
    display: flex;
    align-self: flex-start;
  }
}

.buttonMobileContainer {
  margin: 0 auto;
  width: 160px;

  @media tablet {
    display: none;
  }
}

.buttonMobile {
  padding: 8px 24px;
  text-transform: uppercase;
}
