@value desktop-l,desktop-m,desktop-s,tablet,main-font,secondary-font from "@/styles/variables.module.css";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 28px;

  @media desktop-s {
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    height: 750px;
  }

  @media desktop-m {
    gap: 28px;
  }
}

.cardsMediaWrapper {
  flex: 1;
}

.cardsWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  height: 100%;

  @media desktop-s {
    flex: 1;
  }

  @media desktop-m {
    gap: 28px;
  }
}

.cardsWrapper.withoutSlider {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media tablet {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media desktop-s {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.sliderContainer {
  /* to extend the last visible card to the right */
  margin-right: -16px;
}

.mainCardWrapper {
  @media desktop-s {
    display: flex;
    flex: 1;
  }
}
