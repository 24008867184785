@value desktop-l,desktop-m,desktop-s,tablet,main-font,secondary-font,peach,light-peach,main-dark,bannerCardImageZIndex,bannerCardHoverZIndex from "@/styles/variables.module.css";

.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: light-peach;
  max-width: 100vw;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  max-width: 640px;

  @media desktop-s {
    flex-direction: row;
    padding: 58px;
    min-width: unset;
    max-width: 1440px;
  }

  @media desktop-m {
    padding-right: 48px;
    padding-left: 48px;
  }
}

.header {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  font-family: Ogg;

  @media desktop-s {
    font-size: 48px;
    line-height: 64px;
  }
}

.image {
  width: 100%;

  @media desktop-s {
    min-width: 480px;
  }
}

.textContainer {
  padding: 18px;

  @media desktop-s {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 52px;
    padding: 20px;
  }
}

.overH1ElementsContainer {
  @media desktop-s {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 24px;
    width: 100%;
  }
}

.breadcrumbsSmallScreen.breadcrumbsSmallScreen {
  flex: 0 0 auto;

  @media desktop-s {
    display: none;
    flex: unset;
  }
}

.breadcrumbsBigScreen.breadcrumbsBigScreen {
  display: none;
  flex: unset;

  @media desktop-s {
    display: flex;
    flex: 0 0 auto;
  }
}

.commaAfterBreadcrumbsDesktop {
  display: none;

  @media desktop-s {
    display: block;
    font-size: 12px;
    line-height: 12px;
  }
}

.sponsoredText {
  display: flex;
  align-items: center;
  gap: 3px;
  margin-top: 0px;
  margin-bottom: 32px;
  color: peach;
  font-weight: 500;
  font-size: 10px;
  line-height: 23px;
  font-family: secondary-font;
  letter-spacing: 0.05em;

  @media desktop-s {
    margin-top: 16px;
  }
}

.date {
  margin-top: 22px;
  margin-bottom: 20px;
  font-weight: 300;
  font-size: 10px;
  line-height: 23px;
  font-family: secondary-font;
  letter-spacing: 0.05em;

  @media desktop-s {
    margin: 0;
    line-height: 12px;
  }
}

.dateAndShareButtonsContainer {
  display: flex;
  align-items: center;
  width: 100%;

  @media desktop-m {
    flex-direction: row;
    align-items: flex-start;
  }
}

.bookmarkButton {
  margin-left: auto;
  width: unset;
}

.author {
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
  font-family: secondary-font;
  text-align: left;
  text-decoration-skip-ink: none;
  text-underline-position: from-font;
}

.author a {
  font-weight: 500;
  text-decoration: underline;
}

.introductionCopy {
  margin-top: 48px;
  border-left: 1px solid black;
  padding-left: 20px;
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  font-family: secondary-font;
}

.newlineAfterAuthor {
  @media desktop-s {
    display: none;
  }
}
