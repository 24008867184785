.container {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.wrapper {
  position: relative;
  aspect-ratio: 16 / 9;
  width: 100%;
  height: 100%;
}
