@value tablet, desktop-s, desktop-m, secondary-font, desktop-l, main-dark from "@/styles/variables.module.css";

.container {
  display: flex;
  flex-wrap: wrap;
  background-color: main-dark;
  padding-right: 16px;
  color: #fff;

  @media tablet {
    padding-right: 48px;
  }

  @media desktop-s {
    padding-right: 24px;
  }

  @media desktop-m {
    padding-right: 48px;
  }

  @media desktop-l {
    padding-left: 48px;
  }
}

.group {
  display: flex;
  flex-wrap: wrap;
}

.typography {
  margin: 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
  font-family: secondary-font;
}

.item {
  font-weight: 500;
  text-decoration: underline;
}

.item:hover {
  color: #fff !important;
  text-decoration: underline;
}
