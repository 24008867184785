@value tablet, desktop-s, secondary-font, main-font, main-dark from '@/styles/variables.module.css';

.heroBanner {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.meta {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 24px 0 16px 0;

  color: main-dark;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 23px;
  font-family: secondary-font;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
}

.category {
  font-weight: 500;
}

.icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* optical zero */
  margin-top: 2px;
}

.bookmark {
  margin-left: 24px;
  width: 10px;
}

.bookmark > svg {
  width: 10px;
  height: 14px;
}

.title {
  margin: 0;
  margin-bottom: 24px;
  color: main-dark;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  font-family: main-font;
  letter-spacing: -0.02em;
  text-align: center;

  @media desktop-s {
    font-size: 48px;
    line-height: 64px;
    letter-spacing: 0;
  }
}
