@value main-dark, secondary-font from '@/styles/variables.module.css';

.lightboxContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  height: 100%;
  overflow: hidden;
}

.lightboxImage {
  padding: -12px;
  max-width: 100vw;
  max-height: 60vh;
  object-fit: contain;
}

.lightboxControls {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.lightboxDescription {
  padding: 0 8px;
  text-align: center;
}

.lightboxDescription p {
  margin: 0;
  color: white;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  font-family: secondary-font;
}

.credits {
  font-size: 14px;
  line-height: 20px;
}

.lightboxNavigation {
  display: flex;
  column-gap: 12px;
  justify-content: center;
  align-items: center;
}

.lightboxArrow {
  cursor: pointer;
  border: none;
  border-radius: 2px;
  padding: 10px 12px;
}

.arrowPrevIcon {
  transform: rotate(180deg);
}

.lightboxButtonClose {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
  font-family: secondary-font;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35vh;

  @media desktop-s {
    height: 55vh;
  }
}

.loading::before {
  display: block;
  animation: spin 1s linear infinite;
  border: 4px solid #fff;
  border-top: 4px solid main-dark;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  content: '';
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
