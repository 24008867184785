@value light-peach, main-dark, desktop-s, desktop-m, desktop-xxl from '@/styles/variables.module.css';

.beautySliderContainer {
  background-color: light-peach;
  overflow: hidden;
}

.fashionSliderContainer {
  overflow: hidden;
}

.supplierSliderContainer {
  overflow: hidden;
}

.realWeddingsContainer {
  background-color: main-dark;
  padding: 48px 0 24px 0;

  @media desktop-s {
    padding: 72px 0;
  }
}

.instagramSection {
  margin: 0;
  padding: 0;

  @media desktop-s {
    padding: 0 24px;
  }
}

.articleSliderInnerContainer {
  padding: 0 0 0 16px;

  @media tablet {
    padding: 0 48px;
  }

  @media desktop-s {
    padding: 0 24px;
  }

  @media desktop-m {
    padding: 0 48px;
  }

  @media desktop-xxl {
    padding: 0;
  }
}
